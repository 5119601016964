//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OneToManyFormInput',
  props: {
    type: String,
    headers: Array,
    label: String,
    model: Array
  },
  data: function () {
    return {
      currentIndex: 0
    }
  },
  methods: {
    addRow (event) {
      const entry = {}
      for (var header of this.headers) {
        entry[header.key] = ''
      }
      this.model.push(entry)
    },
    removeRow (event) {
      this.model.splice(event.target.dataset.rowid.substring(4), 1)
      event.stopPropagation()
      event.preventDefault()
    },
    handleInput ($event) {
      this.$emit('input', this.model)
    }
  }
}
